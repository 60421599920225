<template>
	<div class="login"  id="constellation-net">
		<div class="form">
			<form @submit.prevent="login">
				<div class="logo">
					<div class="d-flex justify-content-center">
						<img src="https://static.bienparabien.com/constelacion/img/constelacion-logoblanco.png" alt="Bien para Bien" >
					</div>
				</div>
				<div class="title text-center">
					<h1>Bienvenidos</h1>
				</div>
				<div class="form-body">
					<div class="row form-group">
						<div class="col-sm-12">
							<small><label for="exampleInputEmail1">Usuario</label></small>
							<input v-model="usuario" type="text" name="usuario" id="usuario" class="form-control" autocomplete="off" placeholder="">
						</div>
					</div>
					<div class="row form-group">
						<div class="col-sm-12">
							<small><label for="exampleInputPassword1">Contraseña</label></small>
							<input v-model="password" type="password" name="password" id="password" class="form-control" placeholder="">
							<div class="showHidePassword" @click="showHidePassword"><img id="img-password" src="https://s3.us-east-2.amazonaws.com/static.bienparabien.com/constelacion/test/img/hide.svg" title="Mostrar contraseña"></div>
						</div>
					</div>
					<button type="submit" class="btn principal-btn">Iniciar sesión</button>
					<br />
					<div class="text-center">
						<router-link :to="{name: 'constelacion_recovery'}" class="text-center link-underline">Recuperar contraseña</router-link>
					</div>

				</div>
			</form>
		</div>
		<vue-particles color="#3DADF2"
			:particleOpacity="0.7"
			:particlesNumber="76"
			shapeType="circle"
			:particleSize="5"
			linesColor="#3DADF2"
			:linesWidth="2"
			:lineLinked="true"
			:lineOpacity="1"
			:linesDistance="150"
			:moveSpeed="4"
			:hoverEffect="true"
			hoverMode="grab"
			:clickEffect="true"
			clickMode="push">
		</vue-particles>
	</div>
</template>

<script type="text/javascript">
	import account from '@/api/account';

	export default {
		data: function() {
			return {
				usuario: null
				,password: null
			}
		}
		,methods: {
			login: function() {
				account.login({usuario: this.usuario, password: this.password})
				.then(res => {
					this.$log.info('res', res);

					if (!res.data.verificationCode) {
						this.$store.commit('setCredentials', res.data);

						if (!res.data.user.password_reset)
							this.$router.push({name: 'dashboard'});
						else
							this.$router.push({name: 'constelacion_password_reset'});
					}else {
						this.$log.info('Doble autenticación activado');
						this.$store.commit('set',{verify: res.data});
						this.$router.push({name: 'twoauth'})
					}
				})
				.catch(err => {
					this.$log.info('err', err.response);

					if (err.response && err.response.status && err.response.status == 403)
						return this.$helper.showMessage('Error','Acceso denegado','error','alert');

					this.$helper.showAxiosError(err,'Error');
				})
			}
			,showHidePassword: function() {
				let pass = document.getElementById('password');
				let img = document.getElementById('img-password')
				if (pass.type == 'password') {
					pass.type = 'text';
					img.src = 'https://s3.us-east-2.amazonaws.com/static.bienparabien.com/constelacion/test/img/visible.svg';
					img.title = 'Ocultar contraseña';
				}else {
					pass.type = 'password';
					img.src = 'https://s3.us-east-2.amazonaws.com/static.bienparabien.com/constelacion/test/img/hide.svg'
					img.title = 'Mostrar contraseña';
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
@import '@/general-styles/components/forms.scss';
@import '@/general-styles/components/particles.scss';

	.login {
    position: relative;
		z-index: 1;
		.logo {
			z-index: 1;
			img {
				height: 340px;
				width: 300px;
        margin: 1rem 0;
			}
		}
		.form {
			background: rgba(255,255,255,.69);
			padding: 2rem;
			width: 400px;
			.title {
				h1 {
					color: #fff !important;
				}
			}
			.principal-btn {
				margin: 1.5rem 0;
			}
			.showHidePassword {
				width: 30px;
				position: absolute;
				top: 33px;
				right: 21px;
				img {
					width: 100% !important;
				}
			}
			.form-body{
				padding: 0;
				margin: 0;
				background: transparent;
			}
		}
	}
	@media only screen and (max-width: 1023px){
		.login{
			.form{
				background: transparent;
			}
			.form-body{
				background: rgba(255,255,255,.69) !important;
				padding: 0 1rem !important;
				label{
					color: #4e4e4e !important;
				}
			}
		}
	}
</style>